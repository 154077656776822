import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ConfirmDialog from "@/view/pages/ConfirmDialog.vue";
import { mapGetters } from "vuex";
import MFCategoriesApiService from "@/core/services/api.service.mf-categories";

export default {
  name: "mainFeedCategories",
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.mainFeedCategories");
    },
    headers() {
      return [
        {
          //text: this.$i18n.t("pages.mfCategories.categoryName"),
          text: "Category ID",
          align: "left",
          value: "categoryIds",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.mfCategories.name"),
          align: "left",
          value: "name",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.mfCategories.image"),
          value: "image",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.mfCategories.city"),
          value: "city",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.mfCategories.active"),
          value: "published",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.mfCategories.hideFromFeed"),
          value: "hideFromFeed",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.mfCategories.order"),
          align: "center",
          sortable: false,
          value: "ord"
        },
        { text: "", align: "center", value: "actions", sortable: false, width: "1%" }
      ];
    }
  },
  data() {
    return {
      categoryId: null,
      categoryName: "",
      cityName: "",
      shopCategories: [],
      totalShopCategories: 0,
      loading: true,
      dialogDelete: false,
      errors: [],
      mfCategories: [],
      loadingMfCategories: true
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` }]);
    await MFCategoriesApiService.init();
    this.loadMFCategories();
  },
  components: {
    ConfirmDialog
  },
  methods: {
    async changePublishAsk(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.shopCategories.deleteMessage") + item.name + " ?"
        )
      ) {
        this.changePublish(item, false);
      }
    },

    async loadMFCategories() {
      this.loadingMfCategories = true;
      await MFCategoriesApiService.getAllMFCategories()
        .then(res => {
          this.mfCategories = res?.data;
          console.log("MF cat", this.mfCategories);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loadingMfCategories = false));
    },

    changePublish(id, publish) {
      this.loading = true;
      let params = {};
      params["published"] = publish;
      MFCategoriesApiService.updateActiveCategory(id, publish)
        .then(res => {
          console.log(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          "Are you sure you want to delete category " + item.name + " ?"
        )
      ) {
        this.deleteRecord(item);
      }
    },

    deleteRecord(item) {
      MFCategoriesApiService.deleteCategory(item.id)
        .then(res => {
          console.log(res);
          this.loadMFCategories();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
